<template>
  <div class="page">
    <PageTitle title="推广设置"></PageTitle>
    <div class="page-content">
      <div class="block">
        <div class="header">
          <span>佣金提现门槛</span>
        </div>
        <div class="block-content">
          <span class="left">设置类型：</span>
          <el-radio-group v-model="type">
            <el-radio :label="1">按品牌设置</el-radio>
            <el-radio :label="2">按类目设置</el-radio>
          </el-radio-group>
        </div>
        <span class="tips">注：设置类型为单选</span>
        <div class="add">
          <el-button type="primary" @click="openDlg">{{1 === type ? "按品牌设置" : "按类目设置"}}</el-button>
        </div>
        <el-table class="list tb-table" :data="tableData">
          <el-table-column :label="columnTitle" prop="name"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block-content">
          <span>分销比例：</span>
          <el-input placeholder="请输入" width="140px" v-model="rate"></el-input>
          <span>百分比</span>
        </div>
      </div>
      <div class="fix-confirm">
        <el-button type="primary" @click="saveProduct">确定</el-button>
      </div>
    </div>
    <el-dialog :title="type === 1 ? '选择品牌' : '选择分类'" :visible.sync="categoryDlg">
      <div class="dialog-h">
          <el-cascader filterable :options="brandOptions" v-model="selectBrand" v-show="type === 1"></el-cascader>
          <el-cascader filterable :options="categoryOptions" v-model="selectCategory" style="width: 294px;" v-show="type === 2"/>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="categoryDlg = false">取 消</el-button>
        <el-button type="primary" @click="confirmSelect">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import PageTitle from '../../components/PageTitle.vue'
  export default {
    components:{
      PageTitle
    },
    data() {
      return {
        type:1,
        brandList: [],
        categoryList: [],
        rate: '',
        selectCategory: '',
        selectBrand: '',
        categoryDlg: false
      }
    },
    methods: {
      deleteItem(row) {
        if (this.type === 1) {
          let index = this.brandList.indexOf(row)
          this.brandList.splice(index, 1)
        } else {
          let index = this.categoryList.indexOf(row)
          this.categoryList.splice(index, 1)
        }
      },
      confirmSelect() {
        if (this.type === 1) {
          if (!this.selectBrand) {
            this.$message.error('请选择品牌')
            return 
          }
          let brand = null
          this.brandOptions.forEach(item => {
            if (this.selectBrand[0] === item.id) {
              brand = item
            }
          })
          let item = {id:brand['id'], name:brand['brandName']}
          this.brandList.push(item)
          this.categoryDlg = false
        } else if (this.type === 2) {
          if (!this.selectCategory) {
            this.$message.error('请选择分类')
            return 
          }
          let mapItem = null
          this.categoryOptions.forEach(top => {
            if (top.id === this.selectCategory[0]) {
              let secondChildList = top['child'];
              secondChildList.forEach(second => {
                if (second.id === this.selectCategory[1]) {
                  let thirdChildList = second['child']
                  thirdChildList.forEach(thirdItem => {
                    if (thirdItem.id === this.selectCategory[2]) {
                      mapItem = thirdItem
                    }
                  })
                }
              })
            }
          })
          let item = {id:mapItem['id'], name:mapItem['categoryName']}
          this.categoryList.push(item)
          this.categoryDlg = false
        }
      },
      async saveProduct() {
        if (this.tableData.length === 0) {
          this.$message.error('请选择对应类目/品牌')
          return
        }
        if (!this.rate) {
          this.$message.error('请输入分销比例')
          return
        }
        let ids = []
        this.tableData.forEach(item => {
          ids.push(item.id)
        })
        ids = ids.join(',')
        let params = {ids: ids, type: this.type, return_rate: parseInt(this.rate*100 + 0.5)}
        const url = 'admin/seller/product/add'
        const data = await this.$https.post(url, params)
        if (!data) return
        this.$message.success('保存成功')
      },
      openDlg() {
        this.selectBrand = ''
        this.selectCategory = ''
        this.categoryDlg = true
      }
    },
    computed: {
      ...mapState(['brandOptions']),
      ...mapState(['categoryOptions']),
      columnTitle() {
        return this.type === 1 ? '品牌名称' : '分类名称'
      },
      tableData() {
        return this.type === 1 ? this.brandList : this.categoryList
      }
    }
  }
</script>

<style scoped="scoped">
  .page-content {
    padding: 24px;
  }
  .block {
    width: 100%;
    background-color: white;
    padding-bottom: 24px;
  }
  .block .header {
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 32px;
  }
  .block .header span {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0,0,0, 0.85);
  }
  .block .block-content {
    display: flex;
    padding: 24px 24px 0 24px;
    align-items: center;
  }
  .block .block-content .el-input {
    margin-left: 4px;
    margin-right: 4px;
    width: 140px;
  }
  .block .tips {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0,0,0,0.45);
    padding: 24px 24px 0 24px;
  }
  .block-content .left {
    font-size: 14px;
    color: rgba(0,0,0,0.85);
  }
  .block .add {
    margin: 16px 24px 0 24px;
  }
  .block .list {
    padding: 16px 24px 0 24px;
  }
</style>
